//LIBS
global.jQuery = require('./lib/jquery-2.1.4.min');
require('./lib/angular.min');
require('./lib/angular-sanitize.min');
require('./lib/angular-messages.min');
require('./lib/jquery-ui');
require('./lib/date');
require('./lib/ui-datepicker-hu');
require('./lib/design-select.js');
require('./lib/ng-file-upload.min.js');
require('./lib/closest.polyfill.js');
//require('./lib/angular-dropdowns.min');

var app = angular.module('DynaFormCore', ['ui.date', 'ngMessages', 'ngFileUpload', 'ngSanitize']); //['ngDropdowns'])

// Factories
app.factory('jQuery', require('./factory/jQuery'));
app.factory('dynaFormFactory', require('./factory/dyna-form-factory'));

// Controllers
app.controller('coreController', require('./controller/dyna-form-core-controller'));
app.controller('fileUploadController', require('./controller/dyna-form-file-upload-controller'));

// Directives
app.directive('scrollToFirstError', require('./directive/scroll-to-first-error'));
app.directive('multiSelect', require('./directive/multi-select'));
app.directive('numbersOnly', require('./directive/numbers-only'));
app.directive('emailValidator', require('./directive/email-validator'));
app.directive('designSelect', require('./directive/design-select'));
app.directive('designCheckbox', require('./directive/design-checkbox'));
app.directive('captcha', require('./directive/captcha_posta'));
app.directive('checkboxGroup', require('./directive/checkbox-group'));
app.directive('radioButtons', require('./directive/radio-buttons'));
