module.exports = ['$timeout', function ($timeout) {
	return {
		restrict: 'A',
		link: function ($scope, $elem, attr) {
			$scope.$watch(attr.ngModel, function (newVal, oldVal) {
				$timeout(function () {
					if (!$elem.prop("checked")) {
						$elem.parent().removeClass("checkbox--active");
					}
				}, 0);
			});

			//$timeout(function(){
			//	if(window.designSelect){
			//		window.designSelect($elem)
			//	}
			//});
		}
	};
}];
