module.exports = ['$scope', 'dynaFormFactory', function ($scope, dynaFormFactory) {
	$scope.children = [];
	$scope.sessionId = new Date().getTime();
	var lastErrorsFromServer = [];
	$scope.checkboxValidations = {};
	$scope.data = {};
	$scope.currentStep = $scope.currentStep || 1;


	$scope.isCheckboxGroupRequired = function (name, min) {
		var checked = 0;
		for (var key in $scope[name]) {
			checked += !!$scope[name][key];
		}
		$scope.checkboxValidations[name] = checked >= min;
	};

	function resetLastErrors() {
		for (var i = 0; i < lastErrorsFromServer.length; i++) {
			var error = lastErrorsFromServer[i];

			for (var j = 0; j < error.errors.length; j++) {
				$scope.DynaForm[error.field].$setValidity(error.errors[j], true);
			}
		}
	}

	function isFormValid(formName) {
		var isValid = $scope[formName].$valid;
		var isCheckboxGroupValid = angular.equals({},$scope.checkboxValidations) ? true :
			Object.keys($scope.checkboxValidations).map(function(key){return $scope.checkboxValidations[key]}).indexOf(true) >= 0;
		$scope.children.forEach(function (controller) {
			if (controller.isFileUploadValid) {
				isValid = isValid && controller.isFileUploadValid();
			}
		});

		return isValid && isCheckboxGroupValid;
	}

	$scope.getCaptcha = function () {
		return dynaFormFactory.getCaptcha();
	};

	$scope.addFileUploadData = function (data) {
		$scope.children.forEach(function (controller) {
			if (controller.schemaName && controller.uploadedFiles && controller.uploadedFiles.length) {
				var filaData = {};
				for (var i = 0; i < controller.uploadedFiles.length; i++) {
					filaData[i] = controller.uploadedFiles[i];
				}
				data[$scope.currentStep][controller.schemaName] = filaData;
			}
		});
	};

	$scope.addCheckboxGroupData = function (data) {
		Object.keys($scope.checkboxValidations).forEach(function(key) {
			data[$scope.currentStep][key] = $scope[key] || {}
		});
	};

	$scope.sendForm = function (formName) {
		var data = JSON.parse(JSON.stringify($scope.data));
		resetLastErrors();
		lastErrorsFromServer = [];

		if (isFormValid(formName)) {
			dynaFormFactory.convertNumbersToStringInObject(data);
			$scope.addFileUploadData(data);
			$scope.addCheckboxGroupData(data);
			dynaFormFactory.sendForm(data, formName, $scope.sessionId, function (response) {
				console.log('sendForm response:', response);

				if (response.statusText == 'OK') {
					if (response.data.status === "OK") {
						$scope.responseSuccess = true;
						window.scrollTo({top: 0, behavior: 'smooth'});
					} else {
						var invalidFields = response.data[$scope.currentStep];

						for (var i = 0; i < invalidFields.length; i++) {
							var invalidField = invalidFields[i];

							for (var ii = 0; ii < invalidField.errors.length; ii++) {
								var fieldName = invalidField.field;
								var errorKey = invalidField.errors[ii];

								$scope[formName][fieldName].$error[errorKey] = true;
								$scope[formName][fieldName].$invalid = true;
							}
						}
					}
				}
			})
		} else {
			console.log('A form nem valid');
		}
	};

	$scope.bindCaptchaId = function (captchaElementId) {
		var captchaId = angular.element('#INBcaptcha .captcha-image').attr('src').match(/(m|s)[0-9]*.jpg/)[0].match(/(m|s)[0-9]*/)[0];
		$scope.data[$scope.currentStep][captchaElementId].id = captchaId;
	};

	$scope.initMultiselect = function (selectModelName, selectOptionsArrayName) {
		if (typeof window[selectOptionsArrayName] != 'undefined') {
			$scope[selectOptionsArrayName] = window[selectOptionsArrayName];
			$scope[selectModelName] = [];
		}
	};

	$scope.toggleMultiselectItem = function (model, item) {
		var itemIndex = model.indexOf(item);

		if (itemIndex == -1) {
			model.push(item);
		} else {
			model.splice(itemIndex, 1);
		}
	}

	$scope.resetForm = function () {
		$scope.data[$scope.currentStep] = {};
	};

	//DATEPICKER
	$scope.datepickerOptions = {
		changeMonth: !0,
		changeYear: !0,
		showOn: 'button',
		buttonImage: '/static-ut/sw/g/myts-asp/datepicker.png',
		buttonImageOnly: true,
		buttonText: "Válasszon dátumot",
		maxDate: '+1m'
	};

}];
