module.exports = ['$parse', function ($parse) {
    return {
        restrict: 'E',
        transclude: true,
        scope: {
            model: '=model',
            options: '=options',
            toggleItem: '&',
            multiselectSelectAll: '&'
        },
        templateUrl: 'multiselect.html',
        compile: function (tElem, tAttr) {

            function getOptionsValue(valueName, labelName) {
                var retValue = 'item';

                if (valueName && valueName.length) {
                    retValue += '.' + valueName;
                }

                retValue += ' as item';

                if (labelName && labelName.length) {
                    retValue += '.' + labelName;
                }

                retValue += ' for item in options';

                console.log(retValue);
                return retValue;
            }

            var select = tElem.find('select');
            select.attr('data-ng-options', getOptionsValue(tAttr.value, tAttr.label));

            /*return {
             pre: function(a, b, c){
             alert('pre');
             },
             post: function linkFunction($scope, $elem, attr, ctrl, $transclude){
             var innerScope = $scope.$new();

             $transclude( innerScope, function( clone ) {
             $elem.find('li').empty();
             $elem.find('li').append( clone );
             $elem.on( '$destroy', function() {
             innerScope.$destroy();
             });
             });
             }
             }*/
        }
    }
}];