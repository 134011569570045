module.exports = ['$scope', 'Upload', '$attrs', 'dynaFormFactory', '$http', function ($scope, Upload, $attrs, dynaFormFactory, $http) {
	$scope.$parent.children.push($scope);
	$scope.log = '';
	$scope.filesSizeOriginal = 0;
	$scope.filesSize = 0;
	$scope.files = [];
	$scope.uploadedFiles = [];
	$scope.uploadingFiles = [];
	$scope.uploadingProcesses = {};
	$scope.showUploadError = false;
	$scope.showUploadValidationError = false;
	$scope.uploads = [];
	$scope.maxFileCount = $attrs.maxFileCount;
	// CMS-bol nem szerkesztheto, de BE ez a default ertek
	$scope.maxUploadTotalSize = $attrs.maxUploadTotalSize || 50;
	$scope.maxUploadFileSize = $attrs.maxUploadFileSize || 10;
	$scope.fileUploadRequired = $attrs.fileUploadRequired;
	$scope.filePattern = $attrs.filePattern;
	$scope.schemaName = $attrs.schemaName;
	var formName = document.querySelector('[data-schema-name="' + $scope.schemaName + '"]').closest('form').attributes['name'].value;
	var sessionId = $scope.$parent.sessionId;
	var apiPrefix = '/webform-backend/requests/forms/' + formName + '/sessions/' + sessionId;
	// var UPLOAD_ERROR_TYPES = ['max-size', 'max-total-size', 'file-extension', 'max-file-count', 'filename-pattern', 'required', 'default'];


	$scope.$watch('files', function () {
		$scope.reset();
		$scope.calculateFilesSize();
		$scope.checkSizeBeforeUpload();
	});

	$scope.checkSizeBeforeUpload = function () {
		if (!$scope.showSizeError() && !$scope.showTypeError()) {
			$scope.uploadFiles();
		}
	};

	$scope.reset = function (index) {
		var filename = $scope.uploadingFiles[index];
		if (filename) {
			$scope.uploads.splice(index, 1);
			$scope.uploadingFiles.splice(index, 1);
			delete $scope.uploadingProcesses[filename];
			$scope.removeFile(index);
		} else {
			$scope.uploads = [];
			$scope.uploadingFiles = [];
			$scope.uploadingProcesses = {};
		}
	};

	$scope.removeFile = function (index) {
		$scope.files.splice(index, 1);
		$scope.calculateFilesSize();
		$scope.checkSizeBeforeUpload();
	};

	$scope.calculateFilesSize = function () {
		var filesSize = 0;
		$scope.files.forEach(function (file) {
			filesSize += file.size
		});
		$scope.filesSizeOriginal = filesSize;
		$scope.filesSize = $scope.getFileSizeInMb(filesSize);
	};

	$scope.getFileSizeInMb = function (filesSize) {
		return Math.round(filesSize * 100 / 1024 / 1024) / 100;
	};

	$scope.uploadFiles = function () {
		if (!$scope.showFileCountError()) {
			for (var i = 0; i < $scope.files.length; i++) {
				var file = $scope.files[i];
				if (!file.$error) {
					$scope.showUploadError = false;
					$scope.showUploadValidationError = false;
					var apiUrl = apiPrefix + '/pages/' + $scope.$parent.currentStep + '/files/' + $scope.schemaName;
					var upload = Upload.upload({
						url: apiUrl,
						// url: 'https://angular-file-upload-cors-srv.appspot.com/upload',
						data: {
							files: file
						}
					});

					$scope.uploads.push(upload);

					upload.then(function (response) {
						if (Array.isArray(response.data) && response.data.length === $scope.uploadedFiles.length + 1) {
							$scope.handleFileListAfterUpload(response);
						} else {
							$scope.handleFileUploadError(response, $scope.schemaName);
						}
					}, function (error) {
						$scope.showUploadError = true;
						console.log('Error status: ' + error.status);
					}, function (event) {
						var index = $scope.uploadingFiles.indexOf(event.config.data.files.name);
						if (index === -1) {
							$scope.uploadingFiles.push(event.config.data.files.name);
						}
						$scope.uploadingProcesses[event.config.data.files.name] = parseInt(100.0 * event.loaded / event.total);
					});

					upload.xhr(function (xhr) {
						xhr.upload.addEventListener('abort', function () {
							console.warn('abort uploading');
						}, false);
					});
				}
			}
		}
	};

	$scope.handleFileListAfterUpload = function(response){
		if ($scope.uploadedFiles.indexOf(response.config.data.files.name) < 0) {
			$scope.uploadedFiles.push(response.config.data.files.name);

			var uploadingIndex = $scope.uploadingFiles.indexOf(response.config.data.files.name);
			if (uploadingIndex >= 0) {
				$scope.uploadingFiles.splice(uploadingIndex, 1);
			}

			$scope.files.forEach(function (file, filesIndex) {
				if (file.name === response.config.data.files.name) {
					$scope.files.splice(filesIndex, 1);
					$scope.calculateFilesSize();
				}
			});
		}
	};

	$scope.handleFileUploadError = function(response){
		if (response.data[$scope.$parent.currentStep] && response.data[$scope.$parent.currentStep].length) {
			var fields = response.data[+$scope.$parent.currentStep];
			fields.forEach(function (field) {
				if (field.field === $scope.schemaName && field.errors && field.errors.length) {
					$scope.showUploadValidationError = true;
				}
			});
		}
	};

	$scope.cancelUpload = function(index){
		$scope.uploads[index].abort();
		$scope.reset(index);
	};

	$scope.deleteFile = function (filename) {
		var apiUrl = apiPrefix + '/files/' + $scope.schemaName + '/delete?filename=' + filename;
		$http.post(apiUrl).then(function (response) {
			$scope.uploadedFiles = response.data;
		});
	};

	$scope.showUploadButton = function (){
		return !$scope.showSizeError() &&
			$scope.files.length && !$scope.uploadingFiles.length &&
			$scope.uploadedFiles.length !== $scope.files.length
	};

	$scope.showSizeError = function () {
		var maxUploadTotalSize = ($scope.maxUploadTotalSize || 1000) * 1024 * 1024;
		var maxUploadFileSize = ($scope.maxUploadFileSize || 1000) * 1024 * 1024;
		var fileSizeError = false;

		$scope.files.forEach(function (file) {
			fileSizeError = fileSizeError || file.size > maxUploadFileSize;
		});

		return fileSizeError || $scope.filesSizeOriginal > maxUploadTotalSize ;
	};

	$scope.showTypeError = function () {
		var fileTypeError = false;
		var patterns = $scope.filePattern.length ? $scope.filePattern.split(',') : [];

		$scope.files.forEach(function (file) {
			var filename = file.name;
			var ext = '.' + filename.substr(filename.lastIndexOf('.') + 1).toLowerCase();
			fileTypeError = fileTypeError || patterns.indexOf(ext) < 0;
		});

		return fileTypeError;
	};

	$scope.showFileCountError = function () {
		return $scope.files.length + $scope.uploadedFiles.length > $scope.maxFileCount;
	};

	$scope.isFileUploadValid = function () {
		return !($scope.fileUploadRequired === 'true' && !$scope.uploadedFiles.length);
	};
}];
