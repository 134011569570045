/**
 * Created by csaba.kecskes on 2016.02.02..
 */
module.exports = ['$compile', '$timeout', 'jQuery', "$sce", function ($compile, $timeout, $, sce) {
	return {
		restrict: 'E',
		require: "ngModel",
		scope: {
			getCaptcha: "&",
			value: "=ngModel",
			inputPlaceholder:"@"
		},
		link: function (scope, elem, attr, ngModelCtrl) {
			scope.refresh = function () {
				scope.getCaptcha().then(function (result) {
					scope.audio = result.data.audio;
					scope.image = result.data.image;
					scope.value = scope.value || {captchaValue:""};
					scope.value.sessionId = result.data.sessionId;
					scope.value.captchaId = result.data.captchaId;
				});
			};
			scope.refresh();
			scope.$watch("audio", function (newVal) {
				$(elem).find("audio").attr("src", scope.getAudioSrc());
			});
			scope.getAudioSrc = function () {
				if (scope.audio) {
					var src = "data:audio/mp3;base64," + scope.audio;
					//sce.trustAsResourceUrl(src);
					return src;
				}
				return "";
			};
			scope.playAudio = function () {
				$(elem).find("audio")[0].play();
			};
			scope.value = {captchaValue: ""};
			scope.$watch("value.captchaValue", function (newVal) {
				if (newVal) {
					ngModelCtrl.$setValidity("required", true);
				}
				else {
					ngModelCtrl.$setValidity("required", false);
				}
			});
		},
		template:	'<div class="captcha">'+
						'<div class="captcha_group">'+
							'<div class="captcha_group__item">'+
								'<img class="captcha__img" data-ng-src="data:image/png;base64,{{image}}">'+
								'<input placeholder="{{inputPlaceholder}}" type="text" data-ng-model="value.captchaValue">'+
							'</div>'+
							'<div class="captcha_group__item">' +
								'<audio></audio>'+
								'<a class="captcha__button-reload" data-ng-click="refresh()">reload</a>'+
								'<a class="captcha__button-audio" data-ng-click="playAudio()">audio</a>' +
							'</div>'+
						'</div>'+
					'</div>'
	}
}];
