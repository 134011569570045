/**
 * Created by csaba.kecskes on 2/4/2016.
 */
module.exports = ['jQuery', function ($) {
	return {
		restrict: 'A',
		require: "ngModel",
		scope: {
			target: "=ngModel",
			minSelection: "@",
			checkboxGroup: "=",
			layout: "@"
		},
		link: function (scope, elem, attr, ngModelCtrl) {
			scope.target = scope.target || {};
			for (var i = 0; i < scope.checkboxGroup.length; i++) {
				scope.checkboxGroup[i].trueValue = scope.checkboxGroup[i].trueValue === undefined ? true : scope.checkboxGroup[i].trueValue;
				scope.checkboxGroup[i].falseValue = scope.checkboxGroup[i].falseValue === undefined ? false : scope.checkboxGroup[i].falseValue;
				if (scope.checkboxGroup[i].checked) {
					scope.target[scope.checkboxGroup[i].property] = scope.checkboxGroup[i].trueValue;
				}
			}
			scope.minSelection = scope.minSelection === undefined ? 1 : scope.minSelection;
			scope.target = scope.target || {};
			var isValid = function () {
				return $(elem).find("input[type=checkbox]:checked").length >= scope.minSelection;
			};
			scope.$watch("target", function (newVal) {
				$(elem).find("input:checked").parent().addClass("checkbox--active");
				$(elem).find("input:not(:checked)").parent().removeClass("checkbox--active");
				ngModelCtrl.$setValidity("minSelection", isValid());
			}, true)
		},
		template: 	'<div data-ng-if="layout===\'grid\'" class="checkbox-group">'+
						'<div data-ng-repeat="checkbox in checkboxGroup" class="checkbox-group__item">'+
							'<label class="checkbox">' +
								'<input data-design-checkbox type="checkbox" data-ng-model="$parent.$parent.target[checkbox.property]" data-ng-true-value="\'{{checkbox.trueValue.toString()}}\'" data-ng-false-value="\'{{checkbox.falseValue.toString()}}\'">' +
								'<span class="checkbox__box"></span>' +
								'{{checkbox.label}}' +
							'</label>'+
						'</div>'+
					'</div>'+
					'<label data-ng-if="layout!==\'grid\'" class="checkbox" data-ng-repeat="checkbox in checkboxGroup">' +
						'<input type="checkbox" data-design-checkbox data-ng-model="$parent.$parent.target[checkbox.property]" data-ng-true-value="\'{{checkbox.trueValue.toString()}}\'" data-ng-false-value="\'{{checkbox.falseValue.toString()}}\'">' +
						'<span class="checkbox__box"></span>' +
						'{{checkbox.label}}' +
					'</label>'
	}
}];
