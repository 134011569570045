module.exports = ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        link: function ($scope, $elem, attr) {
            $scope.$watch(attr.ngModel, function () {
                $timeout(function () {
                    if(window.designSelectRefresh){
                        window.designSelectRefresh($elem);
                    }
                }, 0);
            });

            $timeout(function(){
                if(window.designSelect){
                    window.designSelect($elem)
                }
            });
        }
    };
}];
