module.exports = ['jQuery', function ($) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            var KEY_CODES = {
                BACKSPACE: 8,
                TAB: 9,
                DELETE: 46,
                ENTER: 13,
                ESCAPE: 27,
                A_LETTER: 65,
                END: 35,
                DOWN_ARROW: 40,
                NUMBER_0: 48,
                NUMBER_9: 57,
                NUMPAD_0: 96,
                NUMPAD_9: 105
            };

            function onlyNumbersOrCursorMovementKeys(e) {

                if (e.altKey == true || e.shiftKey == true) {
                    return false;
                }
                if ($.inArray(e.keyCode, [KEY_CODES.BACKSPACE, KEY_CODES.TAB, KEY_CODES.ENTER, KEY_CODES.ENTER]) !== -1 ||
                        // Allow: Ctrl+A
                    (e.keyCode == KEY_CODES.A_LETTER && e.ctrlKey === true && e.altKey === false) ||
                        // Allow: home, end, left, right, down, up
                    (e.keyCode >= KEY_CODES.END && e.keyCode <= KEY_CODES.DOWN_ARROW)) {
                    // let it happen, don't do anything
                    return true;
                }

                if (e.which != KEY_CODES.BACKSPACE && e.which != 0 && (e.which < KEY_CODES.NUMBER_0 || e.which > KEY_CODES.NUMBER_9)) {
                    return false;
                }

            }

            angular.element(elem).bind('keypress', function (event) {
                return onlyNumbersOrCursorMovementKeys(event);
            });
        }
    }
}];