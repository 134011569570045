module.exports = ['jQuery', function($){
    return {
        restrict: 'A',
        link: function($scope, $elem, attr){
            var $form = $(attr.scrollToFirstError);
            var errorSelector = attr.errorSelector;
            $elem = $($elem);

            function animateToFirstError() {
                var $firstError = $form.find(errorSelector).first();
                if($firstError.length){
                    var elementToScrollPosition = $firstError.offset().top;
                    var currentPosition = $(document).scrollTop();
                    if (elementToScrollPosition < currentPosition) {
                        animateTo($firstError);
                    }
                }
            }

            function animateTo(elem) {
                var viewPortHeight = window.innerHeight || 0;

                $('html, body').animate({
                    scrollTop: Math.ceil(elem.offset().top - viewPortHeight / 2)
                }, 1000);
            }

            function handleClick(){
                setTimeout(animateToFirstError, 100);
            }

            $elem.click(handleClick);
        }
    }
}];