module.exports = ["jQuery",function ($) {
	return {
		restrict: 'A',
		require: "ngModel",
		scope: {
			target: "=ngModel",
			radioButtons: "=",
			name: "@",
			required: "=",
			layout: "@"

		},
		link: function (scope, elem, attr, ngModelCtrl) {
			var isValid = function () {
				return scope.required ? angular.isDefined(scope.target) : true;
			};
			scope.$watch("target", function () {
				$(elem).find("input:checked").parent().addClass("radio--active");
				$(elem).find("input:not(:checked)").parent().removeClass("radio--active");
				var valid = isValid();
				ngModelCtrl.$setValidity("required",valid);
			})
		},
		template: 	'<div data-ng-if="layout===\'grid\'" class="radio-group">'+
						'<div data-ng-repeat="radio in radioButtons" class="radio-group__item">'+
							'<label class="radio" >' +
								'<input type="radio" name="{{name+\'_inner\'}}" data-ng-model="$parent.$parent.target" value="{{radio.value}}" >' +
								'<span class="radio__box"></span>' +
								'<span class="radio__label" data-ng-bind-html="radio.label"></span>' +
							'</label>'+
						'</div>'+
					'</div>'+
					'<label data-ng-if="layout!==\'grid\'" data-ng-repeat="radio in radioButtons" class="radio">' +
						'<input type="radio" name="{{name+\'_inner\'}}" data-ng-model="$parent.$parent.target" value="{{radio.value}}" >' +
						'<span class="radio__box"></span>' +
						'<span class="radio__label" data-ng-bind-html="radio.label"></span>' +
					'</label>'

	}
}];
