module.exports = ['$http', function ($http) {
    //TODO: őket dinamikusan szedni valahonnan
    //var formName = 'kedd';
    //var sessionId = '12345678';
    var proxyPrefix = '/webform-backend';
    var factory = {};

    factory.getCaptcha=function(){
        return $http.get(proxyPrefix + "/plugin/posta/captcha/generate?t=" + new Date().getTime());
    };

    factory.sendForm = function (data, formName, sessionId, cb) {
        var apiUrl = proxyPrefix + '/requests/forms/' + formName + '/sessions/' + sessionId + '/complete';
        $http.post(apiUrl, data).then(cb, cb);
    };

    factory.convertNumbersToStringInObject = function (obj) {
        for(property in obj){
            switch(typeof obj[property]){
                case 'boolean':
                case 'number':
                    obj[property] = '' + obj[property];
                    break;
                case 'object':
                    factory.convertNumbersToStringInObject(obj[property]);
                    break;
                default:
                    break;
            }
        }
    };

    return factory;
}];
