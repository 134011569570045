var designSelectModule = (function() {

    /*!
     * designSelect nativ javascript library for designable dropdown lists. v1.3.0
     * @author sarkiroka
     */
    var designSelectVersion = '1.3.0';
    var designSelectLoaded = false;
    var designSelects = [];
    var designSelectOldKeyDownFunction = null;
    var designSelectOldKeyPressFunction = null;
    var designSelectOldDocumentClickFunction = null;
    var designSelectTypedCharacters = '';
    var designSelectDropDownDowned = false;
    /**
     * document ready function from jQuery
     *
     * @author sarkiroka
     */
    var designSelectOnLoad = function() {
        // Mozilla, Opera and webkit nightlies currently support this event
        if (document.readyState != 'complete') {
            document.onreadystatechange = function() {
                if (document.readyState == 'complete' || document.readyState == 'loaded') {
                    designSelectOnLoaded();
                }
            }
        }
        if (document.addEventListener) {
            document.addEventListener("DOMContentLoaded", function() {
                document.removeEventListener("DOMContentLoaded", arguments.callee, false);
                designSelectOnLoaded();
            }, false);

            // If IE event model is used
        } else if (document.attachEvent) {
            // ensure firing before onload,
            // maybe late but safe also for iframes
            document.attachEvent("onreadystatechange", function() {
                if (document.readyState === "complete") {
                    document.detachEvent("onreadystatechange", arguments.callee);
                    designSelectOnLoaded();
                }
            });

            // If IE and not an iframe
            // continually check to see if the document is ready
            if (document.documentElement.doScroll && window == window.top) {
                (function() {
                    if (designSelectLoaded) {
                        return;
                    }
                    try {
                        // If IE is used, use the trick by Diego Perini
                        // http://javascript.nwbox.com/IEContentLoaded/
                        document.documentElement.doScroll("left");
                    } catch (error) {
                        setTimeout(arguments.callee, 0);
                        return;
                    }
                    // and execute any waiting functions
                    designSelectOnLoaded();
                })();
            }
        }

    }();

    /**
     * ha kÃ©sz a dom akkor rÃ¡Ã¼l minden designSelect class-Ãº elemre
     *
     * @author sarkiroka
     */
    function designSelectOnLoaded() {
        if (designSelectLoaded) {
            return;
        }
        designSelectLoaded = true;
        var selects = document.getElementsByTagName('SELECT');
        for ( var i = 0, i_max = selects.length; i < i_max; i++) {
            var select = selects[i];
            var isDesignSelect = designSelectHasClass(select, 'designSelect');
            var isNotDone = !designSelectHasClass(select, 'designSelectDone')
            if (isDesignSelect && isNotDone) {
                designSelect(select);
            }
        }
    }

    /**
     * a paramÃ©terkÃ©nt Ã¡tadott select elem(ek)et dizÃ¡jnosÃ­tja. kÃ©pes fogadni jquery talÃ¡lati halmazt, prototype-os listÃ¡t, vagy egyszerÅ± elemet is.
     *
     * @param elem
     *            egy vagy tÃ¶bb dizÃ¡jnolandÃ³ select objektum. kapcsolÃ³dniuk kell a dom-hoz
     * @author sarkiroka
     */
    function designSelect(elem) {
        if (typeof elem == 'undefined' || elem == null) {
            designSelectError('designSelect', 'not give an element');
            return;
        }
        if (elem && elem.push && elem.length) {//if give a list of elements then iterate all item
            for ( var i = 0, iMax = elem.length; i < iMax; i++) {//for every item
                designSelect(elem[i]);
            }
            return;
        }
        if (('' + elem.tagName).toUpperCase() != 'SELECT') {
            designSelectError('designSelect', 'not give an select element', elem);
            return;
        }

        var elemClass = elem.className, disabled = elem.getAttribute('disabled') === 'disabled';
        if (designSelectHasClass(elem, 'designSelectDone')) {
            return;
        }//if already done then go next
        var designId = ('designSelect' + Math.random()).replace('.', '');//generate special id for new elements
        designSelects.push(designId);
        designSelectAddClass(elem, 'designSelectDone');//sign already done
        elem.style.display = 'none';//hide select
        //after select insert new dom fragement
        var parent = elem.parentNode;

        var designDiv = document.createElement('div');
        designDiv.setAttribute('id', designId + '_wrapper');
        designSelectAddClass(designDiv, 'designSelect');
        if (disabled) {
            designSelectAddClass(designDiv, 'disabled');
        }

        //insert new element into dom after old select
        parent.insertBefore(designDiv, elem.nextSibling);

        var designA = document.createElement('a');
        designA.setAttribute('class', 'close');
        designA.setAttribute('id', designId + '_show');
        designA.tabIndex = elem.tabIndex;

        designDiv.appendChild(designA);

        var designSpan = document.createElement('span');//The <span> in <a>
        designSpan.setAttribute('id', designId + '_span');

        var selected_text = '';
        if (elem.options.length > 0) {
            selected_text = elem.options[Math.max(elem.selectedIndex, 0)].text;
            var optionHtmlAttribute=elem.options[Math.max(elem.selectedIndex, 0)].getAttribute('data-html');
            if(typeof optionHtmlAttribute!='undefined' && optionHtmlAttribute!=null && optionHtmlAttribute!=''){
                selected_text=designSelectEncodeHtml(optionHtmlAttribute);
            }
        }
        designSpan.innerHTML=selected_text;
        designA.appendChild(designSpan);

        var designUl = document.createElement('ul');//The <ul>
        designUl.setAttribute('id', designId + '_dropDown');
        designUl.style.minWidth = parseInt(designA.offsetWidth) + 'px';

        designDiv.appendChild(designUl);

        designSelectAddClass(designDiv, elemClass);

        designUl.style.display = 'none';//hide dropdown

        //legÃ¶rdÃ­tÃ©skor
        if (!disabled) {
            designA.onmousedown = designSelectDropDownMouseDown;
            designA.onmouseup = designSelectDropDownMouseUp;
            designA.onfocus = designSelectDropDownFocus;
            designA.onclick = designSelectDropDownClick;
        }

        //label-re kattintÃ¡s is aktivÃ¡lja
        var inputId = elem.getAttribute('id');
        if (inputId != null && typeof inputId != 'undefined') {//csak ha van id-ja
            var labelek = document.getElementsByTagName('label');
            if (labelek != null && typeof labelek != 'undefined') {
                for ( var i = 0, i_max = labelek.length; i < i_max; i++) {
                    var label = labelek[i];
                    var forAttribute = label.getAttribute('for');
                    if (forAttribute == inputId) {
                        label.onclick = function(evt) {
                            var selectElem = document.getElementById(this.getAttribute('for'));
                            var designA = selectElem.nextSibling.firstChild;
                            if (!designA.dispatchEvent) {
                                designA.fireEvent('onclick');
                            } else {
                                var e = document.createEvent('MouseEvents');
                                e.initMouseEvent('click', false, true, top, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                                designA.dispatchEvent(e);
                            }
                            if (!evt) {
                                var evt = window.event;
                            }
                            evt.cancelBubble = true;
                            if (evt.stopPropagation) {
                                evt.stopPropagation();
                            }
                            return false;

                        };
                    }
                }
            }
        }

    }

    /**
     * befrissÃ­ti az adott dizÃ¡jnolt elemet. leveszi a korÃ¡bbi dizÃ¡jnt, majd Ãºjra rÃ¡teszi azt
     *
     * @author sarkiroka
     */
    function designSelectRefresh(elem) {
        if (typeof elem == 'undefined' || elem == null) {
            designSelectError('designSelectRefresh', 'not give an element');
            return;
        }
        if (elem && elem.push) {//if give a list of elements then iterate all item
            for ( var i = 0, iMax = elem.length; i < iMax; i++) {//for every item
                designSelectRefresh(elem[i]);
            }
            return;
        }
        designSelectRemoveClass(elem, 'designSelectDone');
        var nextSibling = elem.nextSibling;
        var maxIteration = 10;
        while (nextSibling && nextSibling.nodeType != 1 && maxIteration > 0) {
            maxIteration--;
            nextSibling = nextSibling.nextSibling;
        }
        if (typeof nextSibling != 'undefined' && nextSibling != null && designSelectHasClass(nextSibling, 'designSelect')) {
            var id = nextSibling.getAttribute('id');
            var designId = id.substring(0, id.indexOf('_wrapper'));
            var tempArray = [];
            for ( var i = 0, iMax = designSelects.length; i < iMax; i++) {
                if (designSelects[i] != designId) {
                    tempArray.push(designSelects[i]);
                }
            }
            nextSibling.parentNode.removeChild(nextSibling);
            designSelects = tempArray;
        }
        designSelect(elem);
    }
    /**
     * jelzi hogy egy kattintÃ¡s vette kezdetÃ©t
     *
     * @author sarkiroka
     */
    function designSelectDropDownMouseDown(e) {
        designSelectDropDownDowned = true;
    }
    /**
     * jelzi hogy vÃ©get Ã©rt a kattintÃ¡s
     *
     * @author sarkiroka
     */
    function designSelectDropDownClick(e) {
        designSelectDropDownDowned = false;
    }
    /**
     * legÃ¶rdÃ­tÃ©s fÃ³kusszal
     *
     * @author sarkiroka
     */
    function designSelectDropDownFocus(e) {
        if (!designSelectDropDownDowned) {//ha egÃ©rkatt akkor nem veszsÃ¼k figyelembe, majd a felengedÃ©s megcsinÃ¡lja, amÃºgy meg de
            designSelectDropDown(e, this, true);
        }
    }
    /**
     * legÃ¶rdÃ­tÃ©s kattintÃ¡ssal
     *
     * @author sarkiroka
     */
    function designSelectDropDownMouseUp(e) {
        designSelectDropDown(e, this, false);
    }
    /**
     * legÃ¶rdÃ­tÃ©s
     *
     * @author sarkiroka
     */
    function designSelectDropDown(e, a, focus) {
        if (!e) {
            var e = window.event;
        }
        var id = a.getAttribute('id');
        var designId = id.substring(0, id.indexOf('_show'));
        if (designSelectHasClass(document.getElementById(id + '_wrapper'), 'disabled') || (!focus && designSelectHasClass(a, 'open'))) {//ha nyitott Ã©s nem Ã©pp most nyÃ­lt fÃ³kusszal akkor becsukjuk
            designSelectCloseAllDropdown(designId, false);
            designSelectAddClass(a, 'designSelectFocused');
            e.cancelBubble = true;
            if (e.stopPropagation) {
                e.stopPropagation();
            }
            return false;
        }
        designSelectCloseAllDropdown(designId, true);
        //create new list
        designSelectAddClass(a, 'designSelectFocused');
        designSelectRemoveClass(a, 'close');
        designSelectAddClass(a, 'open');
        var designUl = a.parentNode.getElementsByTagName('UL')[0];
        designUl.style.display = 'block';
        var elem = a.parentNode.previousSibling;
        var maxIter = 100;
        while (1 != elem.nodeType && maxIter-- > 0) {
            elem = elem.previousSibling;
        }
        if (maxIter > 0) {
            var newUl = designSelectCreateChoicesList(designUl, elem);
            designUl.parentNode.replaceChild(newUl, designUl);
            newUl.style.display = 'block';//open this
            newUl.scrollTop = 0;
            var popup = designSelectHasClass(elem, 'popup');
            if (popup) {
                newUl.style.top = -designSelectCalculateActiveLiTop(newUl) + 'px';
            }
            e.cancelBubble = true;
            if (e.stopPropagation) {
                e.stopPropagation();
            }
            designSelectBindBesideClick();
            return false;
        }
    }
    /**
     * mellÃ©kattintÃ¡s kezelÃ©se
     *
     * @author sarkiroka
     */
    function designSelectBesideClick(e) {
        var targ;
        if (!e) {
            var e = window.event;
        }
        if (e.target) {
            targ = e.target;
        } else if (e.srcElement) {
            targ = e.srcElement;
        }
        if (targ.nodeType == 3) {
            targ = targ.parentNode;
        }
        //ha nem linkre kattintott, vagy nem li-ben volt, vagy a li nem ul-ban volt az mellÃ©kattintÃ¡s, be kell csukni mindent
        //kivÃ©ve ha Ã©pp selectre kattintott
        if(typeof targ.tagName!='undefined'){
            if ((targ.tagName.toUpperCase() != 'A') || (targ.parentNode.tagName.toUpperCase != 'LI') || (targ.parentNode.parentNode.tagName.toUpperCase != 'UL')) {
                var needClose = true;
                if (targ.tagName.toUpperCase() == 'SPAN') {
                    targ = targ.parentNode;
                }
                if (targ.tagName.toUpperCase() == 'A') {
                    var id = targ.getAttribute('id');
                    needClose = typeof id == 'undefined' || id == null || id.indexOf('_show') < 5;
                }
                if (targ.tagName.toUpperCase() == 'LI') {
                    needClose = false;
                }
                if (needClose) {
                    designSelectCloseAllDropdown();
                }
                var oldValue = true;
                if (designSelectOldDocumentClickFunction != null) {
                    oldValue = designSelectOldDocumentClickFunction(e);
                }
                return oldValue;
            } else {
                var ulId = targ.parentNode.parentNode.getAttribute('id');
                //ha minden stimmel akkor megnÃ©zzÃ¼k hogy designselet-es ul-rÃ³l van-e szÃ³
                if ((ulId == null) || (typeof ulId == 'undefined') || (ulId.indexOf('designSelect') != 0) || (ulId.indexOf('_dropDown') < 10)) {
                    designSelectCloseAllDropdown();
                }
                var oldValue = true;
                if (designSelectOldDocumentClickFunction != null) {
                    oldValue = designSelectOldDocumentClickFunction(e);
                }
                return oldValue;
            }
        }
    }
    /**
     * kiszÃ¡molja hogy az adott ul-ben lÃ©vÅ‘ aktiv li elem milyen magassÃ¡gban van
     *
     * @author sarkiroka
     */
    function designSelectCalculateActiveLiTop(ul) {
        var lik = ul.getElementsByTagName('LI');
        for ( var i = 0, iMax = lik.length; i < iMax; i++) {
            if (designSelectHasClass(lik[i], 'selected')) {
                return i * parseInt(lik[i].offsetHeight, 10);
            }
        }
        return 0;
    }
    /**
     * becsukja mindet, Ã©s alaphelyzetbe teszi Å‘ket a paramÃ©terektÅ‘l fÃ¼ggÅ‘en
     *
     * @param designId
     *            az aktuÃ¡lis dizÃ¡jnos cucc id-je. ha nem adjuk meg, akkor mindent bezÃ¡r
     * @param onlyOthers
     *            megadja hogy csak a rajta kÃ­vÃ¼lieket kell-e bezÃ¡rni
     * @author sarkiroka
     */
    function designSelectCloseAllDropdown(designId, onlyOthers) {
        var hasFocused = false;
        var all = typeof designId == 'undefined' || null == designId || '' == designId;
        designSelectTypedCharacters = '';
        for ( var i = 0, i_max = designSelects.length; i < i_max; i++) {
            var id = designSelects[i];
            var element = document.getElementById(id + '_show');
            if ((all || (id == designId && !onlyOthers) || (id != designId && onlyOthers)) && ('undefined' != typeof element && null != element)) {
                designSelectRemoveClass(element, 'open');
                if (designSelectHasClass(element, 'designSelectFocused')) {
                    hasFocused = true;
                }
                designSelectRemoveClass(element, 'designSelectFocused');
                designSelectAddClass(element, 'close');
                var dropDown = document.getElementById(id + '_dropDown');
                if (typeof dropDown != 'undefined' && null != dropDown) {
                    dropDown.style.display = 'none';
                }
                designSelectKeyboardVisualfeedback(id);
                var oldSelect = dropDown.parentNode.previousSibling;
                var maxIter = 10;
                while (1 != oldSelect.nodeType && maxIter > 0) {
                    oldSelect = oldSelect.previousSibling;
                    maxIter--;
                }
                if (maxIter > 0) {
                    designSelectChangeShow(id, designSelectGetSelectText(oldSelect));
                }
            }
        }
        if (hasFocused) {//csak ha volt kivÃ¡lasztott
            designSelectUnbindKeydown();
            designSelectUnbindBesideClick();
        }
    }
    function designSelectGetSelectText(select) {
        var retValue = '';
        var index = Math.max(select.selectedIndex,0);
        if (select.options && select.options.length > 0) {
            var option = select.options[index];
            retValue = option.text;
        }
        return retValue;
    }
    /**
     * lekÃ¶ti a mellÃ©kattintÃ¡s esemÃ©nykezelÅ‘jÃ©t, Ã©s visszateszi a rÃ©git
     *
     * @author sarkiroka
     */
    function designSelectUnbindBesideClick() {
        document.onclick = designSelectOldDocumentClickFunction;
        designSelectOldDocumentClickFunction = null;
    }
    /**
     * rÃ¡kÃ¶ti a mellÃ©kattintÃ¡s esemÃ©nykezelÅ‘jÃ©t, Ã©s elmenti a rÃ©git
     *
     * @author sarkiroka
     */
    function designSelectBindBesideClick() {
        designSelectOldDocumentClickFunction = document.onclick;
        if(designSelectOldDocumentClickFunction==designSelectBesideClick){
            designSelectOldDocumentClickFunction=null;
        }
        document.onclick = designSelectBesideClick;
    }
    /**
     * elkÃ©sziti a lenyilÃ³dÃ³ rÃ©szt
     *
     * @author sarkiroka
     */
    function designSelectCreateChoicesList(designUl, elem) {
        var newUl = designUl.cloneNode(false);
        var iMax = elem.options.length;
        var tabIndexBase = 1000;
        var currentOptGroup = '';
        for ( var i = 0; i < iMax; i++) {//every options
            var option = elem.options[i];
            if (option.parentNode.tagName.toUpperCase() == 'OPTGROUP') {
                if (currentOptGroup != option.parentNode.label) {
                    var optGroupLi = document.createElement('li');
                    var optGroupSpan = document.createElement('span');
                    var optGroupLiTextNode = document.createTextNode(option.parentNode.label);
                    designSelectAddClass(optGroupLi, 'designSelectOptGroup');
                    optGroupSpan.appendChild(optGroupLiTextNode);
                    optGroupLi.appendChild(optGroupSpan);
                    newUl.appendChild(optGroupLi);
                    currentOptGroup = option.parentNode.label;
                }

            } else {
                currentOptGroup = '';
            }

            if (!option.disabled) {
                var designLi = document.createElement('li');//One <li>
                if (currentOptGroup != '') {
                    designSelectAddClass(designLi, 'designSelectOptGroupItem');
                }

                if (option.value == elem.value) {
                    designSelectAddClass(designLi, 'selected');
                }
                var designLiA = document.createElement('a');//One <a>
                designLi.appendChild(designLiA);
                designLiA.setAttribute('href', '#' + option.text);
                var optionValue;
                if (typeof option.value == 'undefined' || option.value == null) {
                    optionValue = option.text;
                    option.value = optionValue;
                } else {
                    optionValue = option.value;
                }
                designLiA.setAttribute('rel', optionValue);
                designLiA.onclick = designSelectElementOnclick;
                designLiA.tabIndex = tabIndexBase + i;
                var optionHtml=option.getAttribute('data-html');
                if(typeof optionHtml!='undefined' && optionHtml!=null && optionHtml!='') {
                    optionHtml=designSelectEncodeHtml(optionHtml);
                    designLiA.innerHTML=optionHtml;
                }else{
                    var designLiA_textNode = document.createTextNode(option.text);
                    designLiA.appendChild(designLiA_textNode);
                }
                newUl.appendChild(designLi);
            }
        }
        if (iMax > 0) {//ha van benne legalÃ¡bb egy elem
            designSelectBindKeyboardHandler();
        }
        return newUl;
    }

    function designSelectEncodeHtml(html){
        var retValue=(html+'').replace(/&lt;/g,'<').replace(/&gt;/g,'>').replace(/&quot;/g,'"').replace(/&apos;/g,"'");
        //TODO /&#(\d+);/g cserÃ©je
        retValue=retValue.replace(/&amp;/g,'&');
        return retValue;
    }

    /**
     * a lenyilÃ³dÃ³ rÃ©szbÅ‘l egy elem kattintÃ¡s esemÃ©nye
     *
     * @author sarkiroka
     */
    function designSelectElementOnclick(e) {
        var ul = this.parentNode.parentNode;
        ul.style.display = 'none';
        var lik = ul.getElementsByTagName('li');
        var designId = ul.id.substring(0, ul.id.indexOf('_'));
        for ( var i = 0, i_max = lik.length; i < i_max; i++) {
            designSelectRemoveClass(lik[i], 'selected');
        }
        document.getElementById(designId + '_span').innerHTML = designSelectStripHtml(this.innerHTML);
        designSelectAddClass(this.parentNode, 'selected');
        var rel = this.getAttribute('rel');
        var oldSelect = ul.parentNode.previousSibling;
        if (document.all) {
            for ( var i = 0, iMax = oldSelect.options.length; i < iMax; i++) {
                if (oldSelect.options[i].value == rel) {
                    oldSelect.selectedIndex = i;
                    oldSelect.options[i].selected = true;
                    break;
                }
            }
        } else {
            oldSelect.value = rel;
        }
        var newUl = ul.cloneNode(false);
        ul.parentNode.replaceChild(newUl, ul);
        var designA = document.getElementById(designId + '_show');
        designSelectRemoveClass(designA, 'open');
        designSelectAddClass(designA, 'close');
        if (!oldSelect.dispatchEvent) {
            var e = document.createEventObject();
            e.cancelBubble = false;
            e.returnValue = true;
            e.relatedTarget = oldSelect;
            oldSelect.fireEvent('onchange', e);
        } else {
            var e = document.createEvent('HTMLEvents');
            e.initEvent('change', true, true);
            oldSelect.dispatchEvent(e);
        }
        designSelectUnbindKeydown();
        designSelectUnbindBesideClick();
        return false;
    }

    /**
     * segÃ©gfÃ¼ggvÃ©ny megvizsgÃ¡lja hogy van-e adott class az elemen
     *
     * @author sarkiroka
     */
    function designSelectHasClass(el, selector) {
        if (typeof el == 'undefined' || null == el) {
            return false;
        }
        var className = " " + selector + " ";
        var elemClassName = el.className;
        if ((" " + elemClassName + " ").replace(/[\n\t]/g, " ").indexOf(className) > -1) {
            return true;
        }
        return false;
    }

    /**
     * segÃ©dfÃ¼ggvÃ©ny ami hozzÃ¡ad egy elemhez egy megadott class-t
     *
     * @author sarkiroka
     */
    function designSelectAddClass(element, value) {
        if (typeof element == 'undefined' || null == element) {
            return;
        }
        if (!element.className) {
            element.className = value;
        } else if (!designSelectHasClass(element, value)) {
            element.className = element.className + ' ' + value;
        }
    }

    /**
     * segÃ©dfÃ¼ggvÃ©ny ami kiveszi a megadott class-t az adott elemtÅ‘l
     *
     * @author sarkiroka
     */
    function designSelectRemoveClass(element, cls) {
        if (typeof element == 'undefined' || null == element) {
            return;
        }
        if (designSelectHasClass(element, cls)) {
            var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
            element.className = element.className.replace(reg, ' ');
        }
    }

    /**
     * kezeli a kiÃ­rt szÃ¶veg vÃ¡ltozÃ¡sÃ¡t gÃ©pelÃ©sre
     *
     * @param id
     *            designId amivel azonosÃ­tjuk a dizÃ¡jnolt cuccot
     * @author sarkiroka
     */
    function designSelectHandleTitleStringChange(id) {
        var ul = document.getElementById(id + '_dropDown');
        var ak = ul.getElementsByTagName('A');
        for ( var i = 0, iMax = ak.length; i < iMax; i++) {
            var originalTitle = designSelectStripHtml(ak[i].innerHTML);
            var title = designSelectStripHtml(ak[i].innerHTML,true);
            if (title.toUpperCase().indexOf(designSelectTypedCharacters.toUpperCase()) == 0) {
                designSelectChangeShow(id, originalTitle);
                break;
            }
        }
    }
    /**
     * megvÃ¡ltoztatja a lenyilÃ³hoz tartozÃ³ title Ã©rtÃ©ket
     *
     * @param id
     *            designId amivel azonosÃ­tjuk a dizÃ¡jnolt cuccot
     * @param newValue
     *            Ãºj kiÃ­rt Ã©rtÃ©k
     * @author sarkiroka
     */
    function designSelectChangeShow(id, newValue) {
        var value = newValue;
        var strippedValue = designSelectStripHtml(value, true);
        if (strippedValue.toUpperCase().indexOf(designSelectTypedCharacters.toUpperCase()) == 0) {//tÃ©nyleg
            if(value.toUpperCase().indexOf(designSelectTypedCharacters.toUpperCase())>=0){//tag-ekkel nem megszakÃ­tva is van
                var strongLength = designSelectTypedCharacters.length;
                var needStrong = strongLength > 0;
                if(needStrong){
                    var re=new RegExp('((?:>|^)[^<>]*)('+designSelectTypedCharacters+')','g');
                    value=value.replace(re,'$1<strong class="highlight" data-highlight="true">$2</strong data-highlight="true">');
                    //TODO ez nem illeszkedik ilyen esetben: keresett="alma" kijelzett="<span>al</span>malÃ©".
                }
            }
        }
        document.getElementById(id + '_span').innerHTML = value;
    }
    /**
     * kezeli a pozÃ­ciÃ³ Ã¡llÃ­tÃ¡sÃ¡t gÃ©pelÃ©sre
     *
     * @param id
     *            designId amivel azonosÃ­tjuk a dizÃ¡jnolt cuccot
     * @author sarkiroka
     */
    function designSelectHandleSelectionPosition(id) {
        var ul = document.getElementById(id + '_dropDown');
        var ak = ul.getElementsByTagName('A');
        var firstFound = false;
        var firstLi = null;
        var firstA = null;
        var previousLi = [];
        for ( var i = 0, iMax = ak.length; i < iMax; i++) {
            var a = ak[i];
            var title = designSelectStripHtml(a.innerHTML,true);
            var li = a.parentNode;
            if (!firstFound && title.toUpperCase().indexOf(designSelectTypedCharacters.toUpperCase()) == 0) {
                firstFound = true;
                firstLi = li;
                firstA = a;
            }
            if (!firstFound) {
                previousLi.push(li);
            }
            designSelectChangeListHighlight(a);
            designSelectRemoveClass(li, 'hover');
            designSelectRemoveClass(a, 'hover');
        }
        if (firstFound) {
            designSelectAddClass(firstLi, 'hover');
            designSelectAddClass(firstA, 'hover');
            designSelectSetUlScrollTop(id, previousLi);
        }
    }
    /**
     * beÃ¡llÃ­tja az ul scrolltopjÃ¡t Ãºgy hogy a mÃ¡sodik paramÃ©terben lÃ©vÅ‘ li elemek kilapozÃ³djanak
     *
     * @param id
     *            a dizÃ¡jnolt elem azonosÃ­tÃ³ja
     * @param previousLis
     *            a nem mutatott li elemek listÃ¡ja
     * @author sarkiroka
     */
    function designSelectSetUlScrollTop(id, previousLis) {
        var height = 0;
        for ( var i = 0, iMax = previousLis.length; i < iMax; i++) {
            height += parseInt(previousLis[i].offsetHeight);
        }
        document.getElementById(id + '_dropDown').scrollTop = height;
    }
    /**
     * kiemeli a begÃ©pelt szÃ¶veget a listÃ¡ban
     *
     * @param a
     *            egy listaelemben lÃ©vÅ‘ <a> tag
     * @author sarkiroka
     */
    function designSelectChangeListHighlight(a) {
        var title = designSelectStripHtml(a.innerHTML);
        var strongLength = designSelectTypedCharacters.length;
        if (title.toUpperCase().indexOf(designSelectTypedCharacters.toUpperCase()) == 0) {
            title = '<strong>' + title.substring(0, strongLength) + '</strong>' + title.substring(strongLength);
        }
        a.innerHTML = title;
    }
    /**
     * visszaadja egy tipikusan innerhtml-el elkÃ©rt stringbÅ‘l a highlight-tÃ³l megtisztÃ­tott
     *
     * @param html
     *            a html forrÃ¡s
     * @return a kapott html forrÃ¡s highlight-ektÅ‘l mentesÃ­tett vÃ¡ltozata
     * @author sarkiroka
     */
    function designSelectStripHtml(html,allTags) {
        var retValue=html;
        if(allTags){
            retValue = html.replace(/<[^>]*>/g, '');
        }else{
            retValue = html.replace(/<[^>]*data-highlight="true"[^>]*>/g, '');
        }
        return retValue;
    }
    /**
     * megmutatja a begÃ©pelÃ©shez tartozÃ³ kÃ©pernyÅ‘visszajelzÃ©st
     *
     * @param id
     *            designId amivel azonosÃ­tjuk a dizÃ¡jnolt cuccot
     * @author sarkiroka
     */
    function designSelectKeyboardVisualfeedback(id) {
        designSelectHandleTitleStringChange(id);
        designSelectHandleSelectionPosition(id);
    }
    /**
     * a fent lÃ¡thatÃ³ elemet teszi kivÃ¡lasztottÃ¡, kattintÃ¡st szimulÃ¡l az aktiv li>a elemen
     *
     * @param id
     *            designId amivel azonosÃ­tjuk a dizÃ¡jnolt cuccot
     * @author sarkiroka
     */
    function designSelectClickToActiveRow(id) {
        var ul = document.getElementById(id + '_dropDown');
        var ak = ul.getElementsByTagName('A');
        var selectedA = null;
        for ( var i = 0, iMax = ak.length; i < iMax; i++) {
            var a = ak[i];
            if (designSelectHasClass(a, 'hover')) {
                selectedA = a;
                break;
            }
        }
        if (!selectedA.dispatchEvent) {
            selectedA.fireEvent('onclick');
        } else {
            var e = document.createEvent('MouseEvents');
            e.initMouseEvent('click', false, true, top, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
            selectedA.dispatchEvent(e);
        }
        return false;
    }
    /**
     * ellÃ©pteti az aktiv kijelÃ¶lt sort a paramÃ©terben megadott lenyomott navigÃ¡lÃ³gombnak megfelelÅ‘en
     *
     * @param id
     *            designId
     * @param button
     *            lenyomott navigÃ¡lÃ³ gomb neve (home,end,pageup,pagedown,up,down)
     * @author sarkiroka
     */
    function designSelectNavigateTo(id, button) {
        var ul = document.getElementById(id + '_dropDown');
        var ak = ul.getElementsByTagName('A');
        var nextIndex = -1;
        var currentSelectedIndex = designSelectGetSelectedAIndex(ak);
        button = ('' + button).toLowerCase();
        switch (button) {
            case 'up':
                nextIndex = currentSelectedIndex - 1;
                if (nextIndex < 0) {
                    nextIndex = ak.length - 1;
                }
                break;
            case 'down':
                nextIndex = currentSelectedIndex + 1;
                if (nextIndex >= ak.length) {
                    nextIndex = 0;
                }
                break;
            case 'home':
                nextIndex = 0;
                break;
            case 'end':
                nextIndex = ak.length - 1;
                break;
            case 'pageup':
                var liHeight = ak[0].parentNode.offsetHeight;
                for ( var i = 1, iMax = ak.length; i < iMax; i++) {
                    if (isNaN(liHeight) || liHeight < ak[i].parentNode.offsetHeight) {
                        liHeight = ak[i].parentNode.offsetHeight;
                    }
                }
                var ulHeight = ul.offsetHeight;
                var pageSize = Math.round(ulHeight / liHeight);
                nextIndex = Math.max(0, currentSelectedIndex - pageSize);
                break;
            case 'pagedown':
                var liHeight = ak[0].parentNode.offsetHeight;
                for ( var i = 1, iMax = ak.length; i < iMax; i++) {
                    if (isNaN(liHeight) || liHeight < ak[i].parentNode.offsetHeight) {
                        liHeight = ak[i].parentNode.offsetHeight;
                    }
                }
                var ulHeight = ul.offsetHeight;
                var pageSize = Math.round(ulHeight / liHeight);
                nextIndex = Math.min(ak.length - 1, currentSelectedIndex + pageSize);
                break;
            default:
                designSelectError('designSelectNavigateTo', 'unkown key name: ' + button);
                return;
        }
        if (currentSelectedIndex >= 0) {
            var currentA = ak[currentSelectedIndex];
            designSelectRemoveClass(currentA, 'hover');
            designSelectRemoveClass(currentA.parentNode, 'hover');
        }
        if (nextIndex >= 0) {
            var nextA = ak[nextIndex];
            designSelectAddClass(nextA, 'hover');
            designSelectAddClass(nextA.parentNode, 'hover');
            designSelectChangeShow(id, designSelectStripHtml(nextA.innerHTML));
            var previousLis = [];
            for ( var i = 0; i < nextIndex; i++) {
                previousLis.push(ak[i].parentNode);
            }
            designSelectSetUlScrollTop(id, previousLis);
        }
    }
    /**
     * visszaadja a kivÃ¡lasztott A elem indexÃ©t
     *
     * @param ak
     *            a lenyÃ­lÃ³ban talÃ¡lhatÃ³ A elemek
     * @author sarkiroka
     */
    function designSelectGetSelectedAIndex(ak) {
        var selectedIndex = -1;
        for ( var i = 0, iMax = ak.length; i < iMax; i++) {
            var a = ak[i];
            if (designSelectHasClass(a, 'hover')) {
                selectedIndex = i;
                break;
            }
        }
        return selectedIndex;
    }
    /**
     * write error message is console is present
     *
     * @param function
     *            name
     * @param error
     *            message
     * @author sarkiroka
     */
    function designSelectError(where, what, what2) {
        if (console && console.error) {
            console.error(where, what, what2);
        }
    }
    /**
     * leszedi a designselect beÃ©pÃ­tett billentyÅ±zetkezeÅ‘jÃ©t Ã©s visszateszi a korÃ¡bbit ha volt olyan
     *
     * @author sarkiroka
     */
    function designSelectUnbindKeydown() {
        document.onkeydown = designSelectOldKeyDownFunction;
        document.onkeypress = designSelectOldKeyPressFunction;
        designSelectOldKeyDownFunction = null;
        designSelectOldKeyPressFunction = null;
        designSelectTypedCharacters = '';
    }
    /**
     * lenyitott Ã¡llapotban rÃ¡kÃ¶ti a billentyÅ±zetkezelÃ©st
     *
     * @author sarkiroka
     */
    function designSelectBindKeyboardHandler() {
        designSelectOldKeyDownFunction = document.onkeydown==designSelectSpecialKeyboardHandler?null:document.onkeydown;//akÃ¡rmi is volt rÃ©gen, azt mentjÃ¼k, majd Ãºjat Ã¡llÃ­tunk be
        designSelectOldKeyPressFunction = document.onkeypress==designSelectKeyboardHandler?null:document.onkeypress;//akÃ¡rmi is volt rÃ©gen, azt mentjÃ¼k, majd Ãºjat Ã¡llÃ­tunk be
        document.onkeydown = designSelectSpecialKeyboardHandler;
        document.onkeypress = designSelectKeyboardHandler;
    }
    /**
     * egyszerÅ± gÃ©pelÃ©s kezelÃ©se
     *
     * @author sarkiroka
     */
    function designSelectKeyboardHandler(event) {
        var hasDesignSelectFocused = false;//van-e lenyitott
        var processed = true;
        var selectedDomElement = null;
        var selectedDesignSelectId = '';
        for ( var i = 0, i_max = designSelects.length; i < i_max; i++) {
            selectedDomElement = document.getElementById(designSelects[i] + '_show');
            if (typeof selectedDomElement != 'undefined' && null != selectedDomElement && designSelectHasClass(selectedDomElement, 'designSelectFocused')) {
                selectedDesignSelectId = designSelects[i];
                hasDesignSelectFocused = true;
                break;
            }
        }
        if (hasDesignSelectFocused) {//csak akkor kell csinÃ¡lni bÃ¡rmit, ha volt kivÃ¡lasztott
            if (typeof event == 'undefined') {
                event = window.event;
            }
            var keyCode = event.which ? event.which : event.keyCode;
            var character = String.fromCharCode(keyCode).toUpperCase();
            var englishCharacter = (keyCode >= 65 && keyCode <= 122 && event.charCode != 0);
            var hungarianCharacter = (character == 'Ã' || character == 'Ã‰' || character == 'Ã' || character == 'Å°' || character == 'Å' || character == 'Ãš'
            || character == 'Ã–' || character == 'Ãœ' || character == 'Ã“' || character == ' ');
            var numberCharacter = (keyCode >= 48 && keyCode <= 57 && event.charCode != 0);
            var specialCharacter = (character == ' ' || character == '.' || character == '-' || character == '_' || character == '?' || character == '!'
            || character == '*' || character == '/' || character == '+' || character == ',');
            if (englishCharacter || hungarianCharacter || numberCharacter || specialCharacter) {
                var nextWord = designSelectTypedCharacters + character;
                var found = false;
                var ak = document.getElementById(selectedDesignSelectId + '_dropDown').getElementsByTagName('A');
                for ( var i = 0, iMax = ak.length; i < iMax; i++) {
                    var title = designSelectStripHtml(ak[i].innerHTML,true);
                    if (title.toUpperCase().indexOf(nextWord.toUpperCase()) == 0) {//van olyan listaelem ami Ã­gy kezdÅ‘dik
                        found = true;
                        break;
                    }
                }
                if (found) {
                    designSelectTypedCharacters += character;
                    designSelectKeyboardVisualfeedback(selectedDesignSelectId);
                }
            } else {
                processed = false;
            }
        }
        var retValue = true;
        if ('undefined' != typeof designSelectOldKeyPressFunction && null != designSelectOldKeyPressFunction) {//ha volt valami rÃ©gen akkor azt meghÃ­vjuk
            retValue = designSelectOldKeyPressFunction();
        }
        return retValue && (!hasDesignSelectFocused || !processed);
    }
    /**
     * speciÃ¡lis billentyÅ±k kezelÃ©se
     *
     * @author sarkiroka
     */
    function designSelectSpecialKeyboardHandler(event) {
        var hasDesignSelectFocused = false;//van-e lenyitott
        var processed = true;
        var selectedDomElement = null;
        var selectedDesignSelectId = '';
        for ( var i = 0, i_max = designSelects.length; i < i_max; i++) {
            selectedDomElement = document.getElementById(designSelects[i] + '_show');
            if (typeof selectedDomElement != 'undefined' && null != selectedDomElement && designSelectHasClass(selectedDomElement, 'designSelectFocused')) {
                selectedDesignSelectId = designSelects[i];
                hasDesignSelectFocused = true;
                break;
            }
        }
        if (hasDesignSelectFocused) {//csak akkor kell csinÃ¡lni bÃ¡rmit, ha volt kivÃ¡lasztott
            if (typeof event == 'undefined') {
                event = window.event;
            }
            var keyCode = event.which ? event.which : event.keyCode;
            var needRefresh = true;
            switch (keyCode) {
                case 8: {//backspace tÃ¶rÃ¶ljÃ¼k a begÃ©pelt karakterek kÃ¶zÃ¼l az utolsÃ³t ha volt
                    var charactersLength = designSelectTypedCharacters.length;
                    if (charactersLength > 0) {
                        designSelectTypedCharacters = designSelectTypedCharacters.substring(0, charactersLength - 1);
                    }
                    break;
                }
                case 9: {//tab: jelÃ¶ljÃ¼k hogy elvesztette a fÃ³kuszt, majd bezÃ¡rjuk
                    hasDesignSelectFocused = false;
                    designSelectCloseAllDropdown(selectedDesignSelectId, false);
                    break;
                }
                case 13: {//enter amit fent lÃ¡t, azt kivÃ¡lasztjuk
                    designSelectClickToActiveRow(selectedDesignSelectId);
                    hasDesignSelectFocused = false;
                    event.cancelBubble = true;
                    if (event.stopPropagation) {
                        event.stopPropagation();
                    }
                    if (event.preventDefault) {
                        event.preventDefault();
                    }
                    event.returnValue = false;
                    break;
                }
                case 27: {//escape
                    designSelectCloseAllDropdown(selectedDesignSelectId, false);
                    break;
                }
                case 38: {//up
                    //designSelectStepUpDown(selectedDesignSelectId,-1);
                    designSelectNavigateTo(selectedDesignSelectId, 'up');
                    needRefresh = false;
                    break;
                }
                case 40: {//down
                    //designSelectStepUpDown(selectedDesignSelectId,+1);
                    designSelectNavigateTo(selectedDesignSelectId, 'down');
                    needRefresh = false;
                    break;
                }
                case 36: {//home
                    designSelectNavigateTo(selectedDesignSelectId, 'home');
                    needRefresh = false;
                    break;
                }
                case 35: {//end
                    designSelectNavigateTo(selectedDesignSelectId, 'end');
                    needRefresh = false;
                    break;
                }
                case 33: {//pgup
                    designSelectNavigateTo(selectedDesignSelectId, 'pageup');
                    needRefresh = false;
                    break;
                }
                case 34: {//pgdn
                    designSelectNavigateTo(selectedDesignSelectId, 'pagedown');
                    needRefresh = false;
                    break;
                }
                default: {
                    processed = false;
                }
            }
            if (processed && needRefresh) {
                designSelectKeyboardVisualfeedback(selectedDesignSelectId);
            }
        }
        var retValue = true;
        if ('undefined' != typeof designSelectOldKeyDownFunction && null != designSelectOldKeyDownFunction) {//ha volt valami rÃ©gen akkor azt is meghÃ­vjuk
            retValue = designSelectOldKeyDownFunction();
        }
        return retValue && (!hasDesignSelectFocused || !processed);
    }
    function isDesignSelectLoaded() {
        return designSelectLoaded;
    }
    window.designSelect = designSelect;
    window.designSelectRefresh = designSelectRefresh;
    return {
        "designSelect" : designSelect,
        "designSelectRefresh" : designSelectRefresh,
        "isDesignSelectLoaded" : isDesignSelectLoaded
    }
}());